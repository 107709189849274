import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import Layout from '../components/Layouts/cakeDefault'

const BanhSinhNhatPage = () => {
  useEffect(() => {
    navigate('/')
  }, [])

  return <Layout>Bánh sinh nhật Savor Cake</Layout>
}

export default BanhSinhNhatPage
